.formation-container{
    background-color: #f9f9f9;
}
.formation-title{
    margin-bottom: 0.5rem;
    font-weight: 900;
    font-size: 3.8em;
    line-height: 1.2;
    color: #000;
}
.formation-sub-title{
    font-size: 1.4em;
    background: #fff;
    border-left: 5px solid var(--app-secondary);
    display: inline-flex;
    font-weight: 700;
    padding: 5px 10px;
    color: var(--app-primary);
}

.formation-mini-title{
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 900;
    line-height: 1.2;
    color: var(--app-primary);
}


.formation-btn-primary{
    background-color: var(--app-primary);
    border-color: var(--app-primary);
    color: white;
    border-radius: 0px;
    font-weight: 900;
    background-size: 200% auto;
    background-image: linear-gradient(to right, #282d87 0%, #3846d0 51%, #282d87 100%);
    border: none;
}
.formation-btn-secondary{
    background-color: var(--app-secondary);
    border-color: var(--app-secondary);
    color: white;
    border-radius: 0px;
    font-weight: 900;
    background-size: 200% auto;
    background-image: linear-gradient(to right, #db202f 0%, #ff374b 51%, #d4303d 100%);
    background-image: linear-gradient(to right, #d4303d 0%, #ea4e3b 51%, #ea4e3b 100%);
    border: none;
}


.formation-resume-box-r{
    padding: 2em;
    background: #fff;
    box-shadow: -4px 7px 24px 0 rgb(0 0 0 / 10%);
}
.formation-resume-box-l{}

.formation-description-dark{
    font-size: 1.4em;
    line-height: initial;
    color: var(--app-dark);
}

.formation-description-light{
    font-size: 1.4em;
    line-height: initial;
    color: #fff;
}

.disabled-area{
    pointer-events:none;
}

.formation-objectif-container:before{
    clip-path: polygon(0 80%, 100% 0%, 100% 100%, 0 100%);
    height: 60px;
    background: #212529;
    content: " ";
    display: block;
    width: 100%;
    position: absolute;
    z-index: 1;
    left: 0px;
    top: -59px;
}

.formation-objectif-container:after{
    clip-path: polygon(0 0%, 100% 0%, 100% 20%, 0 100%);
    height: 60px;
    background: #212529;
    content: " ";
    display: block;
    width: 100%;
    position: absolute;
    z-index: 1;
    left: 0px;
    bottom: -59px;
}
.formation-objectif-container{
    background-color: #212529;
    position: relative;
    margin-top: 60px !important;
    margin-bottom: 60px !important;
}

.formation-v-nav .nav-link {
    background: #fff;
    border: 0;
    border-radius: 0px;
    color: var(--app-dark);
    font-weight: 900;
    text-transform: capitalize;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.09);
    margin-bottom: 4px;
}
.formation-v-nav .nav-link.active, .formation-v-nav .show>.nav-link {
    color: #fff;
    background-color: var(--app-secondary);
}

.formation-info-box{
    border: 1px solid #ffac00;
}

.formation-info-box-title{
    width: fit-content;
    background: #f9f9f9;
    position: relative;
    top: -13px;
    left: 22px;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: 900;
    color: var(--app-primary);
}

.formation-list-container{
    background: #fff;
    border-bottom: var(--app-secondary) 3px solid;
    box-shadow: -4px 7px 24px 0 rgb(0 0 0 / 10%);
}
.formation-item a {
    text-decoration: none !important;
}


.formation-filter-container{
    background: #0000000a;
    border-radius: 4px;
}

.btn-formation-filter{
    background: #fff;
    border-radius: 50px;
    padding: 4px 24px;
    font-weight: 900;
    color: #000;
    box-shadow: 0 0 11px rgba(8,13,132,.13);
}

.btn-formation-filter:hover , .btn-formation-filter:focus , .btn-formation-filter:active , .btn-formation-filter-active{
    background: var(--app-primary) !important;
    color: #fff !important;
}
