h1 , h2 {
  font-weight: 900;
}


.fnt-w4 {
  font-weight: 400 !important;
}

.fnt-w5 {
  font-weight: 500 !important;
}

.fnt-w7 {
  font-weight: 700 !important;
}

.fnt-w8 {
  font-weight: 800 !important;
}
.fnt-w9 {
  font-weight: 900 !important;
}

.fnt-wb {
  font-weight: bold !important;
}

.fnt-sm {
  font-size: small !important;
}

.fnt-xsm {
  font-size: x-small !important;
}

.fnt-smlr {
  font-size: smaller !important;
}

.fnt-med {
  font-size: medium !important;
}

.fnt-larg {
  font-size: large !important;
}

.fnt-largr {
  font-size: larger !important;
}


.txt-primary{
  color: var(--app-primary);
}
.txt-secondary{
  color: var(--app-secondary);
}
.txt-light{
  color: var(--app-light);
}
.txt-dark{
  color: #000;
}


.dvdr-r-gray{
  border-right: #0000003b 0.5px solid !important;
}
.dvdr-l-gray{
  border-left: #0000003b 0.5px solid !important;
}
.dvdr-t-gray{
  border-top: #0000003b 0.5px solid !important;
}
.dvdr-b-gray{
  border-bottom: #0000003b 0.5px solid !important;
}


.no-border-rad{
    border-radius: 0 !important;
  }
  
  .h-100{
    height: 100vh;
  }

  .app-btn-primary{
    background-color: var(--app-primary);
    border-color: var(--app-primary);
    color: white;
    border-radius: 0px;
    font-weight: 900;
}
.app-btn-secondary{
    background-color: var(--app-secondary);
    border-color: var(--app-secondary);
    color: white;
    border-radius: 0px;
    font-weight: 900;
}


