.section-title{
    font-size: 2rem;
    line-height: 1.2;
}

.ly-bg-primary{background-color: var(--app-primary);}
.ly-bg-secondary{background-color: var(--app-secondary);}
.ly-bg-primary-grad{
    background-size: 200% auto;
    background-image: linear-gradient(to right, #282d87 0%, #3846d0 51%, #282d87 100%);
}
.ly-bg-secondary-grad{
    background-size: 200% auto;
    background-image: linear-gradient(to right, #d4303d 0%, #ea4e3b 51%, #ea4e3b 100%);
}
.ly-bg-white{background-color: #fff;}
.ly-bg-dark{background-color: #212529;}
.ly-bg-gray{background-color: #e4e6f5;}

.ly-btn-primary{
    background-color: var(--app-primary);
    border-color: var(--app-primary);
    color: white;
    border-radius: 0px;
    font-weight: 900;
}
.ly-btn-secondary{
    background-color: var(--app-secondary);
    border-color: var(--app-secondary);
    color: white;
    border-radius: 0px;
    font-weight: 900;
}

.ly-btn-dark{
    background-color: var(--app-dark);
    border-color: var(--app-dark);
    color: white;
    border-radius: 0px;
    font-weight: 900;
}

.ly-btn-hide{
    display: none !important;
}

.var_box{
    border: #ffffff73 2px solid;
}

.z-ind99{
    z-index: 99;
}

.ly-img-compress{
    padding: 1rem!important;
}

.ly-img-expand{
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}


.ly-z-99{
    z-index: 99;
}

.ly-hero-container{
    min-height: 720px;
}

.ly-hero-grad-bg1{
    background: linear-gradient(0deg,#282d87,#6455db);
}
.ly-hero-grad-bg2{
    background: linear-gradient(45deg, rgba(41,47,136,1) 45%, #6455db 100%);
}
.ly-hero-grad-bg3{
    background: linear-gradient(45deg, rgba(41,47,136,1) 45%, rgba(212,48,61,1) 100%);
}
.ly-hero-grad-bg4{
    background: linear-gradient(45deg, #d4303d 45%, #ea4e3b 100%);
}